import styled from "styled-components"
import {Collapse} from "antd"
import {ArrowUpOutlined} from "@ant-design/icons"

const FAQ = ({questions}) => {

	return (
		<Wrapper>
			<Title>
				FAQ
			</Title>
			<Content>
				<Collapse
					bordered={false}
					expandIcon={({isActive}) => <ArrowUpOutlined style={{fontSize: 32}} rotate={isActive ? 180 : 0}/>}
					items={questions}
					/*items={}*/
				/>
			</Content>
		</Wrapper>

	)
}

export default FAQ

const Wrapper = styled.div`
    display: flex;

    .ant-collapse-header {
        padding: 20px 0 20px 0 !important;
        font-size: 32px;
    }

    .ant-collapse-content-box {
        padding: 0 0 20px 0 !important;
        font-size: 20px;
    }
`
const Title = styled.div`
    width: 450px;
    padding-top: 20px;
    font-size: 56px;
`
const Content = styled.div`
    flex: 1;
`
