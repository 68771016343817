import {Route, Routes, useLocation} from "react-router-dom"
import styled from "styled-components"
import {Layout} from 'antd'
import Header from "./Layout/Header"
import {useEffect} from "react"
import HomeScreen from "./Home/HomeScreen"
import Footer from "./Layout/Footer"
import RoutingScreen from "./Routing/RoutingScreen"
import PriceScreen from "./Price/PriceScreen"
import ContactScreen from "../navigation/Contact/ContactScreen"
import MonitoringScreen from "./Monitoring/MonitoringScreen"
import MobileAppScreen from "./MobileApp/MobileAppScreen"
import FinesScreen from "./Services/Fines/FinesScreen"
import ReportsScreen from "./Services/Reports/ReportsScreen"
import MotivationScreen from "./Services/Motivation/MotivationScreen"
import ObserversScreen from "./Services/Observers/ObserversScreen"
import TelegramScreen from "./Services/Telegram/TelegramScreen"
import TransportScreen from "./Services/Transport/TransportScreen"
import CameraScreen from "./Services/Camera/CameraScreen"
import DocumentsScreen from "./Services/Documents/DocumentsScreen"
import DirectorScreen from "./Services/Director/DirectorScreen"
import PrivacyScreen from "./Docs/PrivacyScreen"
import TemperatureScreen from "./Services/Temperature/TemperatureScreen"

const AppNavigation = () => {

	const location = useLocation()

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [location.pathname])

	return (
		<Layout>
			<Wrapper>
				<Header/>
				<Content>
					<Routes>
						<Route path="/*" element={<HomeScreen/>}/>
						<Route path="/routing" element={<RoutingScreen/>}/>
						<Route path="/monitoring" element={<MonitoringScreen/>}/>
						<Route path="/mobile-app" element={<MobileAppScreen/>}/>

						<Route path="/services/reports" element={<ReportsScreen/>}/>
						<Route path="/services/fines" element={<FinesScreen/>}/>
						<Route path="/services/motivation" element={<MotivationScreen/>}/>
						<Route path="/services/observers" element={<ObserversScreen/>}/>
						<Route path="/services/telegram" element={<TelegramScreen/>}/>
						<Route path="/services/transport" element={<TransportScreen/>}/>
						<Route path="/services/camera" element={<CameraScreen/>}/>
						<Route path="/services/documents" element={<DocumentsScreen/>}/>
						<Route path="/services/director" element={<DirectorScreen/>}/>
						<Route path="/services/temperature" element={<TemperatureScreen/>}/>

						<Route path="/price" element={<PriceScreen/>}/>
						<Route path="/contact" element={<ContactScreen/>}/>

						<Route path="/docs/privacy" element={<PrivacyScreen/>}/>
						{/*<Route path="/mobile-app" element={<MobileAppScreen/>}/>
						<Route path="/monitoring" element={<MobileAppScreen/>}/>
						<Route path="/services" element={<ServicesScreen/>}/>
						<Route path="/docs/privacy" element={<PrivacyScreen/>}/>
						<Route path="/api" element={<APIScreen/>}/>*/}
					</Routes>
				</Content>
				<Footer dark={false}/>
			</Wrapper>
		</Layout>
	)
}

export default AppNavigation

const Wrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: #FFFFFF;
`
const Content = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
`
