import styled from "styled-components"

const ContentBlock = ({children, ...props}) => {

	return (
		<Wrapper {...props}>
			<Content {...props}>
				{children}
			</Content>
		</Wrapper>
	)
}

export default ContentBlock

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    background-color: ${props => props.background ?? 'transparent'};
    padding-top: ${props => props.top ?? 120}px;
    padding-bottom: ${props => props.bottom ?? 120}px;
`
const Content = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 1320px;
    padding: 0 25px;
    gap: ${props => typeof props.gap !== "undefined" ? props.gap : 120}px;
`
