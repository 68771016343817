import ContentBlock from "../../../components/UI/ContentBlock"
import ContentCard from "../../../components/UI/ContentCard"
import styled from "styled-components"
import {useEffect} from "react"
import {useNavigate} from "react-router-dom"
import FAQ from "../../../components/FAQ"
import {Button} from "antd"

const TelegramScreen = () => {

	const navigate = useNavigate()

	useEffect(() => {
		document.title = 'АТС24 — Кабинет клиента'
	}, [])

	return (
		<>
			<ContentBlock top={60} bottom={0}>
				<ContentCard title="Кабинет клиента" large>
					<p>
						Предоставьте вашим клиентам и партнерам возможность отслеживать состояние их доставок в реальном
						времени.
					</p>
				</ContentCard>
			</ContentBlock>
			<ContentBlock top={40} bottom={0}>
				<Section>
					<img width="100%" src="/assets/img/jpg/services/observers/main.jpg"/>
				</Section>
			</ContentBlock>

			<ContentBlock>
				<ContentCard title="Как это работает?">
					<p>
						Откройте гостевой доступ клиенту или партнёру, укажите доступные точки или маршруты целиком и
						отправьте приглашение.
					</p>
				</ContentCard>

				<ContentCard title="Система меток" small
							 media={{
								 url: "/assets/img/jpg/services/observers/tags.jpg",
								 width: 780
							 }}
				>
					<p>
						Вы можете присваивать произвольные метки точкам, маршрутам, водителям или ТС.
					</p>
					<p>
						Выберите, какие элементы с какими метками доступны клиенту, и данные появятся в кабинете
						автоматически.
					</p>
				</ContentCard>

				<ContentCard title="Что увидит клиент?" small reverse
							 media={{
								 url: "/assets/img/jpg/services/observers/track-radius.jpg",
								 width: 780
							 }}
				>
					<p>
						Маршруты целиком или только те точки, которые были указаны, имя водителя и фактический маршрут
						движения ТС.
					</p>
					<p>
						Ограничьте отображение маршрута радиусом посещенных точек, чтобы клиент видел движение ТС только
						в пределах его точек, как показано на примере.
					</p>
				</ContentCard>

				<ContentCard title="Мониторинг" small
							 media={{
								 url: "/assets/img/jpg/services/observers/monitoring.jpg",
								 width: 780
							 }}
				>
					<p>
						Откройте клиенту доступ к мониторингу для выбранных ТС, и он сможет отслеживать передвижения за
						любой период.
					</p>
				</ContentCard>
			</ContentBlock>

			<ContentBlock background="#F5F5F5">
				<FAQ questions={[
					{
						showArrow: false,
						label: 'Сколько доступов можно добавить?',
						children: 'Ограничение для одного офиса — не более 500 аккаунтов.'
					},
					{
						showArrow: false,
						label: 'Могут ли метки добавляться автоматически?',
						children: 'Конечно, при интеграции с 1С или другими платформами метки к маршрутам или точкам будут присваиваться автоматически.'
					},
					{
						showArrow: false,
						label: 'Как это может пригодиться?',
						children:
							<>
								Если вы транспортная компания, вы можете повысить уровень сервиса и получить
								конкурентное
								преимущество, предоставив своим клиентам доступ к онлайн-мониторингу перевозок.
								<p>
									Если вы пользуетесь услугами транспортных компаний, вы можете предоставить доступ
									своим партнерам, давая им возможность оперативно решать проблемы по вашим заказам
									без вашего участия.
								</p>
							</>
					}
				]}/>
			</ContentBlock>

		</>
	)
}

export default TelegramScreen

const Section = styled.div`

`
