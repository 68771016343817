import {useEffect} from "react"

const TransportScreen = () => {

	useEffect(() => {
		document.title = 'АТС24 — Маршрутизация'
	}, [])

	return (
		<>

		</>
	)
}

export default TransportScreen
