import styled from "styled-components"
import {Modal, Button, QRCode, Input, message} from "antd"
import {CopyOutlined} from "@ant-design/icons"
import {useMemo} from "react"

const ModalDownloadApp = ({selectedStore, setSelectedStore}) => {

	const store = useMemo(() => {
		if (!selectedStore) {
			return {name: 'null', link: 'null'}
		}

		const library = {
			"app-store": {
				link: 'https://apps.apple.com/ru/app/id1607517668',
				name: 'AppStore'
			},
			"google-play": {
				link: 'https://play.google.com/store/apps/details?id=com.twocomrades.ats24&hl=ru',
				name: 'Google Play'
			},
			"app-gallery": {
				link: 'https://appgallery.huawei.com/#/app/C110185921',
				name: 'AppGallery'
			}
		}

		return library[selectedStore]
	}, [selectedStore])

	return (
		<Modal open={selectedStore}
			   width={500}
			   zIndex={9}
			   onCancel={() => setSelectedStore(null)}
			   footer={[<Button color="default" variant="solid" size="large"
								onClick={() => setSelectedStore(null)}>Закрыть</Button>]}
		>
			<Wrapper>
				<StoreName>Установка в {store.name}</StoreName>

				<QRWrapper>
					<QRCode
						size={256}
						value={store.link}
						viewBox={`0 0 256 256`}
					/>
				</QRWrapper>

				<Input
					addonAfter={<CopyOutlined onClick={() => {
						navigator.clipboard.writeText(store.link)
						message.open({
							type: 'success',
							content: 'Ссылка скопирована',
						})
					}
					}/>}
					value={store.link}
					onChange={() => null}
					style={{width: 400}}
				/>
			</Wrapper>
		</Modal>
	)
}

export default ModalDownloadApp

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 50px;
`
const StoreName = styled.div`
    font-size: 26px;
    font-weight: 500;
`
const QRWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-block: 25px;
`
