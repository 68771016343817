import ContentBlock from "../../../components/UI/ContentBlock"
import ContentCard from "../../../components/UI/ContentCard"
import styled from "styled-components"
import {useEffect} from "react"
import {useNavigate} from "react-router-dom"
import FAQ from "../../../components/FAQ"
import {Button} from "antd"

const TelegramScreen = () => {

	const navigate = useNavigate()

	useEffect(() => {
		document.title = 'АТС24 — Телеграм бот'
	}, [])

	return (
		<>
			<ContentBlock top={60} bottom={0}>
				<ContentCard title="Телеграм бот" large>
					<p>
						Система адресных оповещений сотрудников о важных событиях, срывах доставки, нарушений режима
						перевозки и многого другого.
					</p>
				</ContentCard>
			</ContentBlock>
			<ContentBlock top={40} bottom={0}>
				<Section>
					<img width="100%" src="/assets/img/jpg/services/telegram/main.jpg"/>
				</Section>
			</ContentBlock>

			<ContentBlock>
				<ContentCard title="Как это работает?">
					<p>
						Создайте одного или нескольких ботов, укажите для каждого, о каких событиях уведомлять, и
						добавьте их в рабочие чаты. После этого боты начнут отправлять уведомления.
					</p>
				</ContentCard>

				<ContentCard title="Базовые события" small
							 media={{
								 url: "/assets/img/jpg/services/telegram/basic.jpg",
								 width: 780
							 }}
				>
					<p>
						Стартовый набор включает более десяти событий, из которых вы можете выбрать нужные для своих
						ботов.
					</p>
					<p>
						Список постоянно обновляется — следите за новыми событиями в нашем <a
						href="https://t.me/ats24ru_logist">Telegram-канале для
						логистов</a>.
					</p>
				</ContentCard>

				<ContentCard title="Из сервисов" small reverse
							 media={{
								 url: "/assets/img/jpg/services/telegram/services.jpg",
								 width: 780
							 }}
				>
					<p>
						Подключив дополнительные сервисы, вы получите доступ к новым событиям, связанным с ними.
					</p>
					<p>
						Например, при подключении сервиса Штрафы ГИБДД вы сможете получать уведомления о новых
						постановлениях.
					</p>
					<p>
						<Button color="default" variant="solid" size="large"
								onClick={() => navigate('/services/fines')}>
							Подробней о штрафах ГИБДД
						</Button>
					</p>
				</ContentCard>

				<ContentCard title="Индивидуальные" small
							 media={{
								 url: "/assets/img/jpg/services/telegram/custom.jpg",
								 width: 780
							 }}
				>
					<p>
						По вашему запросу мы можем создавать любые события.
					</p>
					<p>
						Например: отправить уведомление, если сегодня четверг, шёл дождь, а водитель Иванов опоздал на
						третью точку.
					</p>
				</ContentCard>
			</ContentBlock>

			<ContentBlock background="#F5F5F5">
				<FAQ questions={[
					{
						showArrow: false,
						label: 'Сколько ботов можно добавить?',
						children: 'Ограничение для одного офиса — не более 20 ботов.'
					},
					{
						showArrow: false,
						label: 'Сколько чатов может обслуживать один бот?',
						children: 'На данный момент существует ограничение: один бот на один чат.'
					},
					{
						showArrow: false,
						label: 'Сколько это стоит?',
						children: 'Боты с базовыми событиями предоставляются бесплатно, а разработка индивидуальных начинается от 25 000 рублей.'
					}
				]}/>
			</ContentBlock>

		</>
	)
}

export default TelegramScreen

const Section = styled.div`

`
