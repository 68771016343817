import styled from "styled-components"
import {useEffect} from "react"

const ReportsScreen = () => {

	useEffect(() => {
		document.title = 'АТС24 — отчёты'
	}, [])

	return (
		<>

		</>
	)
}

export default ReportsScreen
