import styled from "styled-components"
import {Swiper, SwiperSlide} from "swiper/react"
import {Pagination} from "swiper/modules"
import Icon from "../../../components/Icon/Icon"
import {faArrowLeft, faArrowRight} from "@fortawesome/pro-light-svg-icons"
import {useRef, useState} from "react"
import {useNavigate} from "react-router-dom"

const MultiServiceSwiper = () => {
	const swiperRef = useRef()
	const navigate = useNavigate()

	const [reached, setReached] = useState('left')

	return (
		<Wrapper>
			<TopPanel>
				<LeftSide>
					Некоторые сервисы
				</LeftSide>
				<RightSide>
					<ControlButton onClick={() => swiperRef.current.swiper.slidePrev()}
								   disabled={reached === 'left'}>
						<Icon name={faArrowLeft} size={26}/>
					</ControlButton>
					<ControlButton onClick={() => swiperRef.current.swiper.slideNext()}
								   disabled={reached === 'right'}>
						<Icon name={faArrowRight} size={26}/>
					</ControlButton>
				</RightSide>
			</TopPanel>

			<ContentWrapper>
				<Swiper
					ref={swiperRef}
					slidesPerView={'auto'}
					spaceBetween={30}
					pagination={{clickable: true}}
					centeredSlidesBounds={true}
					modules={[Pagination]}
					style={{paddingLeft: 'calc((100vw - 1320px)/2 + 17px)', paddingRight: 25}}
					onSlideChange={e => setReached(e.isBeginning ? 'left' : e.isEnd ? 'right' : null)}
				>
					{/*<SlideWrapper>
						<img width={600}
							 src="https://cdn.sanity.io/images/599r6htc/regionalized/59b7504fab0d1f497159295d796bbfa289771461-1200x800.png?rect=1,0,1199,800&w=700&h=467&q=75&fit=max&auto=format&dpr=2"/>
						<SlideDescription>
							<b>Отчёты и статистика.</b> Streamline the product development process with.
							<p><a onClick={() => navigate('/services/')}>Узнать больше об отчётах и статистике</a></p>
						</SlideDescription>
					</SlideWrapper>*/}
					<SlideWrapper>
						<img width={600} src="/assets/img/jpg/slider/motivation.jpg"/>
						<SlideDescription>
							<b>Система мотивации водителей.</b> Автоматический учёт качества работы водителей с
							бонусной системой.
							<p><a onClick={() => navigate('/services/motivation')}>Узнать больше о системе мотивации</a>
							</p>
						</SlideDescription>
					</SlideWrapper>
					<SlideWrapper>
						<img width={600} src="/assets/img/jpg/slider/telegram.jpg"/>
						<SlideDescription>
							<b>Telegram бот.</b> Система адресных оповещений сотрудников о важных событиях.
							<p><a onClick={() => navigate('/services/telegram')}>Узнать больше о Telegram боте</a></p>
						</SlideDescription>
					</SlideWrapper>
					<SlideWrapper>
						<img width={600} src="/assets/img/jpg/slider/fines.jpg"/>
						<SlideDescription>
							<b>Штрафы ГИБДД.</b> Автоматическая проверка штрафов с привязкой к водителю.
							<p><a onClick={() => navigate('/services/fines')}>Узнать больше о штрафах ГИБДД</a></p>
						</SlideDescription>
					</SlideWrapper>
					{/*<SlideWrapper>
						<FullList onClick={() => navigate('/services')}>
							Все сервисы <Icon style={{marginLeft: 10}} name={faArrowRight} size={26}/>
						</FullList>
					</SlideWrapper>*/}
				</Swiper>
			</ContentWrapper>
		</Wrapper>
	)
}

export default MultiServiceSwiper

const Wrapper = styled.div`
    height: 700px;
    user-select: none;
`
const TopPanel = styled.div`
    display: flex;
    margin: 24px 0;
    align-items: flex-end;
`
const LeftSide = styled.div`
    display: flex;
    flex: 1;
    font-size: 20px;
    text-transform: uppercase;
`
const RightSide = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
`
const ControlButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 48px;
    background-color: ${props => props.disabled ? 'default' : 'rgba(0, 0, 0, 5%)'};
    cursor: ${props => props.disabled ? 'default' : 'pointer'};
    border: solid 1px rgba(0, 0, 0, 5%);

    :hover {
        background-color: ${props => props.disabled ? 'default' : '#000000'};
        color: ${props => props.disabled ? 'default' : '#FFFFFF'};
    }
`
const ContentWrapper = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
`
const SlideWrapper = styled(SwiperSlide)`
    width: 600px;
    height: 600px;
`
const SlideDescription = styled.div`
    padding-top: 20px;
    font-size: 24px;
    font-weight: 300;

    a {
        font-size: 18px;
    }
`
const FullList = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 400px;
    font-size: 36px;
    border: solid 2px #000000;
    border-radius: 10px;
    cursor: pointer;
`
