import AppNavigation from "./navigation"
import {BrowserRouter} from "react-router-dom"
import {ConfigProvider} from "antd"
import "./css/index.css"
import "./css/slider.css"
import "./css/mapbox.css"
import Styled from "./inizialize/Styled"

const App = () => {
	return (
		<BrowserRouter>
			<ConfigProvider theme={{
				/*components: {
					Slider: {
						handleColor: '#FFFFFF',
						handleLineWidth: 0,
						handleActiveColor: 'transparent',
						handleActiveOutlineColor: 'none',
						handleActiveBg: 'transparent',
						handleSize: 25,
						handleSizeHover: 25,
						hoverBorderColor: 'red',
						railSize: 10,
						trackBg: '#197BFF',
						trackHoverBg: '#197BFF'
					}
				},*/
				token: {fontSize: 16, colorLink: "#000000", linkHoverDecoration: "underline"}
			}}>
				<Styled>
					<AppNavigation/>
				</Styled>
			</ConfigProvider>
		</BrowserRouter>
	)
}

export default App


