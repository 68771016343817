import ContentBlock from "../../../components/UI/ContentBlock"
import ContentCard from "../../../components/UI/ContentCard"
import {Button} from "antd"
import styled from "styled-components"
import {useEffect} from "react"

const RoutingScreen = () => {

	useEffect(() => {
		document.title = 'АТС24 — Умная камера'
	}, [])

	return (
		<>
			<ContentBlock top={60} bottom={0}>
				<ContentCard title="Умная камера" large>
					<p>
						Находит коробки, сканирует накладные, считает грузы, тару и выполняет много других задач.
					</p>
				</ContentCard>
			</ContentBlock>
		</>
	)
}

export default RoutingScreen

const Section = styled.div`
    -webkit-box-shadow: 0px 0px 10px 2px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 10px 2px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 10px 2px rgba(34, 60, 80, 0.2);
    border-radius: 10px;
    overflow: hidden;
`
const TilesWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 80px;
    width: 1160px;
`
const TileWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 540px;

    img {
        margin-bottom: 32px;
    }
`
