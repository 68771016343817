import styled from "styled-components"
import {useEffect} from "react"
import ContentBlock from "../../../components/UI/ContentBlock"
import ContentCard from "../../../components/UI/ContentCard"
import {useNavigate} from "react-router-dom"
import {Button} from "antd"

const DirectorScreen = () => {
	const navigate = useNavigate()

	useEffect(() => {
		document.title = 'АТС24 — Приложение руководителя'
	}, [])

	return (
		<>
			<ContentBlock top={60} bottom={0}>
				<ContentCard title="Руководителю" large>
					<p>
						Статистка, информация о маршрутах, точках и водителях за любой день.
					</p>
				</ContentCard>
			</ContentBlock>
			<ContentBlock top={40} bottom={0}>
				<Section>
					<img width="100%" src="/assets/img/jpg/services/director/main.jpg"/>
				</Section>
			</ContentBlock>

			<ContentBlock>
				<ContentCard title="Как это работает?">
					<p>
						Просто скачайте то же приложение, которое используют водители, и вместо номера аккаунта введите
						свой email и пароль.
					</p>
				</ContentCard>
			</ContentBlock>
			<ContentBlock top={0}>
				<ContentCard title="Терминал" small
							 media={{
								 url: "/assets/img/jpg/services/director/terminal.jpg",
								 width: 780
							 }}
				>
					<p>
						На главной странице размещены виджеты с основной информацией о состоянии доставки.
					</p>
					<p>
						По вашему желанию мы можем создавать индивидуальные графики и статистику.
					</p>
				</ContentCard>

				<ContentCard title="Маршруты" small reverse
							 media={{
								 url: "/assets/img/jpg/services/director/routes.jpg",
								 width: 780
							 }}
				>
					<p>
						Поиск по маршрутам, просмотр точек, время их посещения и маршрут движения ТС.
					</p>
				</ContentCard>

				<ContentCard title="Транспорт" small
							 media={{
								 url: "/assets/img/jpg/services/director/transport.jpg",
								 width: 780
							 }}
				>
					<p>
						Список всех ТС в компании, их местоположение и маршрут движения ТС за любой период времени.
					</p>
				</ContentCard>

				<ContentCard title="Водители" small reverse
							 media={{
								 url: "/assets/img/jpg/services/director/drivers.jpg",
								 width: 780
							 }}
				>
					<p>
						Поиск по водителям, их контактная информация и категория в системе мотивации.
					</p>
					<p>
						<Button onClick={() => navigate('/services/motivation')} color="default" variant="solid"
								size="large">
							Подробнее о системе мотивации
						</Button>
					</p>
				</ContentCard>
			</ContentBlock>
		</>
	)
}

export default DirectorScreen
const Section = styled.div`

`