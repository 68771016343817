import ContentBlock from "../../components/UI/ContentBlock"
import ContentCard from "../../components/UI/ContentCard"
import styled from "styled-components"
import ContentHeader from "../../components/UI/ContentHeader"
import {useEffect, useState} from "react"
import ModalDownloadApp from "./components/ModalDownloadApp"
import InterestingSolutionsSwiper from "./components/InterestingSolutionsSwiper"
import {Button, Collapse} from "antd"
import CustomerReview from "../../components/CustomerReview"
import {useNavigate} from "react-router-dom"

const MobileAppScreen = () => {
	const navigate = useNavigate()

	const [selectedStore, setSelectedStore] = useState(null)

	useEffect(() => {
		document.title = 'АТС24 — Мобильное приложение для водителя'
	}, [])

	return (
		<>
			<ContentBlock>
				<ContentCard title="Мобильное приложение" large
							 media={{
								 url: "/assets/img/jpg/mobile-app/iphone.jpg",
								 width: 350,
								 radius: 30
							 }}
				>
					<p>
						Самый настоящий помощник водителя: путевой лист, оптимизация маршрутов, электронные накладные,
						архив поездок, график работы и многое другое.
					</p>
					<StoreButtonsWrapper>
						<StoreButton onClick={() => setSelectedStore('google-play')} store="google-play"/>
						<StoreButton onClick={() => setSelectedStore('app-store')} store="app-store"/>
						<StoreButton onClick={() => setSelectedStore('app-gallery')} store="app-gallery"/>
					</StoreButtonsWrapper>
				</ContentCard>
			</ContentBlock>

			<ContentBlock background="#E9FAFF">
				<ContentCard title="Электронное задание" small>
					<p>
						С первого взгляда водитель понимает объем задач, которые ему предстоит выполнить. На всех
						экранах его сопровождают полезная информация по маршруту, подсказки и удобная навигация.
					</p>
				</ContentCard>


				<ContentCard
					reverse title="Список маршрутов" mini
					media={{
						url: "/assets/img/jpg/mobile-app/routes.jpg",
						width: 780
					}}
				>
					<p>
						Все задания удобно распределены по дням и содержат информацию о времени старта, расстоянии,
						продолжительности и объёме работы.
					</p>
				</ContentCard>

				<ContentCard
					title="Интерактивная карта" mini
					media={{
						url: "/assets/img/jpg/mobile-app/map.jpg",
						width: 780
					}}
				>
					<p>
						Карта комбинируется со списком, который можно настроить по высоте.
					</p>
					<p>
						Прямо на карте отображаются маршрут, названия точек и окна приёмок.
					</p>
				</ContentCard>

				<ContentCard
					reverse
					title="Информация о точке" mini
					media={{
						url: "/assets/img/jpg/mobile-app/point.jpg",
						width: 780
					}}
				>
					<Collapse style={{marginTop: 18}} bordered={false} accordion items={[
						{
							label: 'Удобная панель статусов',
							children: 'Всего три кнопки, которые никогда не поставят водителя в тупик.'
						},
						{
							label: 'Переход в навигатор',
							children: 'Когда водитель подтверждает выезд на точку, появляется кнопка навигации, которая отправляет его в навигатор с проложенным маршрутом точно до удобного места разгрузки.'
						},
						{
							label: 'Звонок клиенту',
							children: 'Телефон клиента можно оставить открытым или скрыть, а при нажатии кнопки — соединить водителя с получателем через IP-телефонию.'
						},
						{
							label: 'Список накладных',
							children: 'В приложении имеется точная копия бумажных накладных. Можно проверять соответствие выданных на руки документов, а также делать электронные вычерки.'
						},
						{
							label: 'Оценки и комментарии',
							children: 'Каждый водитель может оценить удобство подъезда, скорость разгрузки, отношение клиента, а также оставить комментарий, который будут видеть другие водители.'
						}
					]}/>
				</ContentCard>

				<ContentCard
					title="Электронные накладные" mini
					media={{
						url: "/assets/img/jpg/mobile-app/invoices.jpg",
						width: 780
					}}
				>
					<p>
						Полный перечень товаров для сдачи или приёмки. Если что-то не было сдано, водитель может
						отметить позицию и указать причину, чтобы логистика сразу узнала об этом.
					</p>
				</ContentCard>
			</ContentBlock>

			<ContentBlock>
				<CustomerReview
					autor="Антон Патрашов, руководитель логистики"
					description="Лучшее мобильное приложение на рынке. Минимум ресурсов на обучение и поддержку водителей"
					media={{
						url: "/assets/img/png/mobile-app/logo-cheremushki.png",
						width: 140
					}}
				/>
			</ContentBlock>

			{/*<ContentBlock bottom={0} background="#FFF2DF">
				<ContentCard title="Интересные решения">
					<p>
						Несколько примеров, как мобильное приложение ежедневно помогает водителю.
					</p>
				</ContentCard>
			</ContentBlock>
			<ContentBlock top={0} background="#FFF2DF">
				<InterestingSolutionsSwiper/>
			</ContentBlock>*/}
			<ContentBlock background="#f3f3f3">
				<TilesWrapper>
					<TileWrapper>
						<img width={540}
							 src="/assets/img/jpg/mobile-app/fix.jpg"/>
						<ContentHeader mini>Фиксированный порядок</ContentHeader>
						<p>
							Вы можете зафиксировать порядок точек, и в этом случае водитель не сможет выполнять их в
							произвольном порядке.
						</p>
					</TileWrapper>
					<TileWrapper>
						<img width={540}
							 src="/assets/img/jpg/mobile-app/radius.jpg"/>
						<ContentHeader mini>Отметка в радиусе</ContentHeader>
						<p>
							Настройте точки, которые могут быть завершены только при физическом присутствии водителя по
							адресу.
						</p>
					</TileWrapper>
					<TileWrapper>
						<img width={540}
							 src="/assets/img/jpg/mobile-app/photo.jpg"/>
						<ContentHeader mini>Прикрепить фото</ContentHeader>
						<p>
							Есть возможность прикрепить фото к любому заказу для подтверждения различных фактов.
						</p>
					</TileWrapper>
					{/*<TileWrapper>
						<img width={540}
							 src="https://cdn.sanity.io/images/599r6htc/regionalized/6538929bdeab8c11b7e97274a48e55fb68c701c3-1560x1248.png?w=540&h=432&q=75&fit=max&auto=format&dpr=2"/>
						<ContentHeader mini>Задание на погрузку</ContentHeader>
						<p>
							Электронный список всех товаров для загрузки на маршрут. Отмечайте галочками загруженные
							позиции, чтобы ничего не забыть.
						</p>
					</TileWrapper>*/}
					<TileWrapper>
						<img width={540}
							 src="/assets/img/jpg/mobile-app/optimization.jpg"/>
						<ContentHeader mini>Оптимизация маршрута</ContentHeader>
						<p>
							В любой момент водитель может оптимизировать маршрут для оставшихся точек, если что-то пошло
							не по плану.
						</p>
					</TileWrapper>
					<TileWrapper>
						<img width={540}
							 src="/assets/img/jpg/mobile-app/discard.jpg"/>
						<ContentHeader mini>Причины отказа</ContentHeader>
						<p>
							Вы можете добавить причины отказов, которые водитель будет выбирать при отмене маршрутных
							листов, точек или вычерках.
						</p>
					</TileWrapper>
					<TileWrapper>
						<img width={540}
							 src="/assets/img/jpg/mobile-app/invoice-sign.jpg"/>
						<ContentHeader mini>Электронная подпись</ContentHeader>
						<p>
							В приложении можно легко отметить вычерки и получить электронную подпись клиента.
						</p>
					</TileWrapper>
				</TilesWrapper>
			</ContentBlock>

			<ContentBlock background="#F3FFE3">
				<ContentCard title="Атоматические статусы" mini
							 media={{
								 url: "/assets/img/jpg/mobile-app/automount.jpg",
								 width: 780
							 }}
				>
					<p>
						Если водитель едет по знакомому маршруту, он может включить функцию автоматических статусов, и
						приложение будет самостоятельно фиксировать посещения точек без его участия.
					</p>
				</ContentCard>

				<ContentCard
					reverse
					title="Свой порядок объезда" mini
					media={{
						url: "/assets/img/jpg/mobile-app/order-points.jpg",
						width: 780
					}}
				>
					<p>
						Перед рейсом водитель может изучить маршрут и при необходимости составить свой собственный план
						объезда точек.
					</p>
					<p>
						Кроме того, можно сохранить порядок объезда, применять его автоматически в будущем и делиться им
						с другими водителями.
					</p>
				</ContentCard>
			</ContentBlock>
			<ContentBlock>
				<TilesWrapper>
					<TileWrapper>
						<img width={540}
							 src="/assets/img/jpg/mobile-app/location-correct.jpg"/>
						<ContentHeader mini>Выбор точной локации</ContentHeader>
						<p>
							Водитель может задать точные координаты подъезда к клиенту, и после проверки они будут
							доступны для других водителей.
						</p>
					</TileWrapper>
					<TileWrapper>
						<img width={540}
							 src="/assets/img/jpg/mobile-app/time-correct.jpg"/>
						<ContentHeader mini>Неверное время приёмки</ContentHeader>
						<p>
							Через приложение можно сообщить о фактическом времени приёмки, если в вашей базе время
							устарело.
						</p>
					</TileWrapper>
				</TilesWrapper>
			</ContentBlock>

			<ContentBlock background="#f4ebff">
				<ContentCard title="Календарь поездок" small
							 media={{
								 url: "/assets/img/jpg/mobile-app/calendar.jpg",
								 width: 780
							 }}
				>
					<p>
						Водителю всегда доступен архив маршрутных листов за любой день с информацией об адресах, пробеге
						и времени работы.
					</p>
				</ContentCard>
				<ContentCard reverse title="Штрафы ГИБДД" small
							 media={{
								 url: "/assets/img/jpg/mobile-app/fines.jpg",
								 width: 780
							 }}
				>
					<p>
						Мы автоматически получаем штрафы из ГИБДД и сразу привязываем их к водителю, который нарушил.
					</p>
					<p>
						При подключении системы мотивации мы можем удерживать сумму штрафа со счёта водителя.
					</p>
					<p>
						<Button color="default" variant="solid" size="large"
								onClick={() => navigate('/services/reports')}>
							Подробней о мотивации водителей
						</Button>
					</p>
				</ContentCard>
			</ContentBlock>
			<ContentBlock background="#000000" bottom={0}>
				<ContentCard title="Тёмная тема" textColor="#FFFFFF"
							 media={{
								 url: "/assets/img/jpg/mobile-app/night-theme.jpg",
								 width: 780
							 }}
				>
					<p>
						Мы заботимся о здоровье водителей, поэтому в приложении есть тёмная тема, которая автоматически
						включается в вечернее время.
					</p>
				</ContentCard>
			</ContentBlock>

			<ModalDownloadApp selectedStore={selectedStore} setSelectedStore={setSelectedStore}/>
		</>
	)
}

export default MobileAppScreen

const StoreButtonsWrapper = styled.div`
    display: flex;
    gap: 20px;
    padding: 40px 0;
`
const StoreButton = styled.div`
    width: 169px;
    height: 50px;
    background-image: url(${props => "/assets/img/png/mobile-app/" + props.store + ".png"});
    background-size: 100% auto;
    transition: opacity .3s;
    cursor: pointer;

    :hover {
        opacity: .7;
    }
`
const TilesWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 80px;
    width: 1160px;
`
const TileWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 540px;

    img {
        margin-bottom: 32px;
    }
`
