import ContentBlock from "../../../components/UI/ContentBlock"
import ContentCard from "../../../components/UI/ContentCard"
import styled from "styled-components"
import {useEffect} from "react"
import FAQ from "../../../components/FAQ"
import CustomerReview from "../../../components/CustomerReview"

const MotivationScreen = () => {

	useEffect(() => {
		document.title = 'АТС24 — Система мотивации водителей, подсчёт зарплаты, бонусная система'
	}, [])

	return (
		<>
			<ContentBlock top={60} bottom={0}>
				<ContentCard title="Система мотивации" large>
					<p>
						Гибкая система автоматического учёта качества работы водителей с личными балансами и бонусной
						системой.
					</p>
				</ContentCard>
			</ContentBlock>
			<ContentBlock top={40} bottom={0}>
				<Section>
					<img width="100%" src="/assets/img/jpg/services/motivation/main.jpg"/>
				</Section>
			</ContentBlock>

			<ContentBlock>
				<ContentCard title="Баллы и категории">
					<p>
						Чем качественнее водитель выполняет задания, тем больше у него баллов и выше категория. В
						зависимости от категории могут изменяться коэффициенты тарифов и премии.
					</p>
				</ContentCard>

				<ContentCard title="Движение баллов" small
							 media={{
								 url: "/assets/img/jpg/services/motivation/points-transaction.jpg",
								 width: 780
							 }}
				>
					<p>
						Вы сами выбираете, за какие события начислять или списывать баллы. Набор таких событий
						ограничивается только вашей фантазией.
					</p>
				</ContentCard>

				<ContentCard title="Смена категорий" small reverse
							 media={{
								 url: "/assets/img/jpg/services/motivation/level-change.jpg",
								 width: 780
							 }}
				>
					<p>
						При каждом начислении или списании баллов водитель автоматически перемещается в подходящую
						категорию.
					</p>
					<p>
						При необходимости смена категорий может происходить по определённым дням или периодам.
					</p>
				</ContentCard>
				<ContentCard title="Коэффициенты" small
							 media={{
								 url: "/assets/img/jpg/services/motivation/coefficient.jpg",
								 width: 780
							 }}
				>
					<p>
						Каждая категория имеет свой коэффициент, который используется при автоматическом подсчёте
						заработной платы.
					</p>
				</ContentCard>
				<ContentCard title="Приложение" small reverse
							 media={{
								 url: "/assets/img/jpg/services/motivation/mobile-app.jpg",
								 width: 780
							 }}
				>
					<p>
						У водителя всегда под рукой детализация движения баллов, а также справочник, в котором указано,
						за что баллы могут списываться и начисляться.
					</p>
				</ContentCard>
			</ContentBlock>

			<ContentBlock background="#E9FAFF">
				<ContentCard title="Лицевой счёт">
					<p>
						За каждым водителем закрепляется лицевой счёт, на который могут быть зачислены или списаны
						средства, в том числе в автоматическом режиме.
					</p>
				</ContentCard>

				<ContentCard title="Зачисления" small
							 media={{
								 url: "/assets/img/jpg/services/motivation/money-income.jpg",
								 width: 780
							 }}
				>
					<p>
						Помимо ручного зачисления, мы автоматически учитываем выполненную работу, умножаем её на
						коэффициент категории водителя и пополняем счёт.
					</p>
				</ContentCard>

				<ContentCard title="Списания" small reverse
							 media={{
								 url: "/assets/img/jpg/services/motivation/money-off.jpg",
								 width: 780
							 }}
				>
					<p>
						Каждое списание может быть привязано к вашему справочнику, чтобы водителю было понятно, за что
						именно сняты деньги с его личного счёта.
					</p>
				</ContentCard>
				<ContentCard title="Безопасность" small
							 media={{
								 url: "/assets/img/jpg/services/motivation/security.jpg",
								 width: 780
							 }}
				>
					<p>
						Как и в банке, система мотивации не допускает редактирования уже проведённых транзакций.
					</p>
					<p>
						Корректировки возможны только путём дополнительного зачисления или списания.
					</p>
				</ContentCard>
			</ContentBlock>

			<ContentBlock>
				<CustomerReview
					autor="Сергей Сергеев, руководитель логистики"
					description="Отличная мотивация для водителей и прозрачные отношения с транспортной компанией"
					media={{
						url: "/assets/img/png/services/motivation/logo-sdg.png",
						width: 180
					}}
				/>
			</ContentBlock>

			<ContentBlock background="#F5F5F5">
				<FAQ questions={[
					{
						showArrow: false,
						label: 'Какие бывают категории?',
						children: 'Список категорий, их названия и возможные коэффициенты вы назначаете сами.'
					},
					{
						showArrow: false,
						label: 'Можно ли использовать свои названия категорий?',
						children: 'Список категорий, их названия и возможные коэффициенты вы назначаете сами.'
					},
					{
						showArrow: false,
						label: 'Правда, что события могут быть абсолютно любыми?',
						children: 'Да, события и условия их возникновения вы можете придумывать сами. Мы лишь ограничиваемся набором данных, которые у нас есть. Также существует возможность обращаться к любым внешним ресурсам для более тонкой настройки.'
					},
					{
						showArrow: false,
						label: 'Могут ли баллы сниматься временно?',
						children: 'Да, баллы могут сниматься на время. К примеру: водитель опоздал на погрузку и мы снего сняли 20 баллов на одну неделю, в течении которой он получает по пониженному коэффициенту. Через неделю баллы восстанавливаются и он возвращается на привычную категорию автоматически.'
					},
					{
						showArrow: false,
						label: 'Можно ли считать категории в конце месяца?',
						children: 'Да, мы можем в течении месяца начислять водителю по базовому тарифу, а в конце месяца умножить все начисления на коэффициент итоговой категории.'
					},
					{
						showArrow: false,
						label: 'Разные маршруты — разные тарифы?',
						children: 'Да, мы можем с вами определить разные категории маршрутов или ТС, и считать их по отдельным формулам.'
					},
					{
						showArrow: false,
						label: 'Сколько стоит эта система?',
						children: 'В среднем 300-600 рублей в месяц за водителя. Финальную стоимость настройки и абонентской платы мы сможем определить после детального обсуждения именно вашей системы мотивации.'
					}
				]}/>
			</ContentBlock>
		</>
	)
}

export default MotivationScreen

const Section = styled.div`

`
