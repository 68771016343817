import styled from "styled-components"

const CustomerReview = ({autor, description, media}) => {

	return (
		<Wrapper>
			<Review>
				”{description}”
			</Review>
			<Customer>
				<Autor>{autor}</Autor>
				<Logo>
					<img src={media.url} width={media.width}/>
				</Logo>
			</Customer>
		</Wrapper>
	)
}

export default CustomerReview

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 60px;
`
const Review = styled.div`
    display: flex;
    font-size: 3.5rem;
    line-height: 1.1;
`
const Customer = styled.div`
    display: flex;
    align-items: center;
`
const Autor = styled.div`
    display: flex;
    flex: 1;
    font-size: 24px;
`
const Logo = styled.div`
`
