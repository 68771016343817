import styled from "styled-components"
import React from "react"
import {HashLink} from 'react-router-hash-link'

const Footer = ({dark = true}) => {

	return (
		<Wrapper dark={dark}>
			<Company>
				АТС24
			</Company>
			<Navigation>
				<NavigationColumn>
					<NavigationTitle>Маршрутизация</NavigationTitle>
					<HashLink to="/routing#map">Совместное использование</HashLink>
					<HashLink to="/routing#metrics">Автоматическое планирование</HashLink>
					<HashLink to="/routing#geocode">Ручной режим</HashLink>
					<HashLink to="/routing#schedule">Наши преимущества</HashLink>
					<HashLink to="/routing#performance">Перенос точек</HashLink>
					<HashLink to="/routing#group-use">Печать документов</HashLink>
					<HashLink to="/routing#group-use">Выбор ворот</HashLink>
				</NavigationColumn>
				<NavigationColumn>
					<NavigationTitle>Мониторинг</NavigationTitle>
					<HashLink to="/monitoring#terminal">Терминал</HashLink>
					<HashLink to="/monitoring#logs">Интерактивная карта</HashLink>
					<HashLink to="/monitoring#divisions">Поиск и фильтры</HashLink>
					<HashLink to="/monitoring#archive">Оперативный отчёт</HashLink>
					<HashLink to="/monitoring#fuel-sensor">Журнал событий</HashLink>
					<HashLink to="/monitoring#temp-sensor">Поддержка филиалов</HashLink>
					<HashLink to="/monitoring#door-sensor">Внешние датчики</HashLink>
				</NavigationColumn>
				<NavigationColumn>
					<NavigationTitle>Приложение</NavigationTitle>
					<HashLink to="/mobile-app#route">Маршрутный лист</HashLink>
					<HashLink to="/mobile-app#points-info">Информация о точках</HashLink>
					<HashLink to="/mobile-app#invoices">Электронные накладные</HashLink>
					<HashLink to="/mobile-app#check-lists">Дополниельные опции</HashLink>
					<HashLink to="/mobile-app#photo-fixation">Автоматические статусы</HashLink>
					<HashLink to="/mobile-app#barcode-scanner">Календарь</HashLink>
					<HashLink to="/mobile-app#government-fines">Штрафы ГИБДД</HashLink>
					<HashLink to="/mobile-app#job-calendar">Тёмная тема</HashLink>
				</NavigationColumn>
				<NavigationColumn>
					<NavigationTitle>Сервисы</NavigationTitle>
					<a href="/services/reports">Отчёты</a>
					<a href="/services/fines">Мотивация водителей</a>
					<a href="/services/motivation">Штрафы ГИБДД</a>
					<a href="/services/telegram">Телеграм бот</a>
					{/*<a href="/services/transport">Управление парком</a>
					<a href="/services/camera">Умная камера</a>
					<a href="/services/documents">Учёт документов и тары</a>
					<a href="/services/director">Для руководителя</a>*/}

					<NavigationTitle style={{marginTop: '1.5rem'}}>Дополнительно</NavigationTitle>
					<HashLink to="/price">Тарифы</HashLink>
					<HashLink to="/contacts">Контакты</HashLink>
					<a href="/docs/privacy">Политика конфиденциальности</a>
					{/*<a href="/docs/privacy">API</a>*/}
				</NavigationColumn>
			</Navigation>
		</Wrapper>
	)
}

export default Footer

const Wrapper = styled.div`
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    padding: 120px calc((100vw - 1320px) / 2 + 25px);
    align-self: center;
    border: solid 1px #EEEEEE;
    background-color: ${props => props.dark ? '#000000' : '#FFFFFF'};
    color: ${props => props.dark ? '#FFFFFF' : '#000000'};

    a {
        text-decoration: none;
        color: ${props => props.dark ? '#FFFFFF' : '#000000'};
    }

    a:hover {
        text-decoration: underline;
        color: ${props => props.dark ? '#FFFFFF' : '#000000'};
    }
`
const Company = styled.div`
    display: flex;
    width: 270px;
    font-size: 43px;
    font-weight: 500;
`
const Navigation = styled.div`
    display: flex;
    flex: 1;
    gap: 50px;
`
const NavigationColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;

`
const NavigationTitle = styled.div`
    text-transform: uppercase;
    color: #697485;
`
