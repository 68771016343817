import styled from "styled-components"
import Mapbox from "../../../components/Mapbox"
import {useEffect, useState} from "react"
import {Button, Progress} from "antd"
import TerminalPointsLayer from "../../../components/Mapbox/components/Layers/TerminalPointsLayer"

const Map = () => {

	const [loading, setLoading] = useState(false)
	const [routes, setRoutes] = useState([])
	const [activeRoute, setActiveRoute] = useState(null)

	const load = async () => {
		setLoading(true)

		fetch('/assets/json/routes.json')  // Замените URL на нужный вам
			.then(response => {
				if (!response.ok) {  // Проверка успешности запроса
					throw new Error('Network response was not ok')
				}
				return response.json()  // Преобразуем ответ в JSON
			})
			.then(data => {
				setRoutes(data)
			})
			.catch(error => {
				console.error('There was a problem with the fetch operation:', error)
			})
	}

	return (
		<Wrapper>
			<MapWrapper silver={!routes.length}>
				<Mapbox id="terminal-map" maxZoom={20} onClick={(e) => console.log(e)}>
					<TerminalPointsLayer routes={routes}/>
				</Mapbox>
			</MapWrapper>
			<LoadDataWrapper show={!routes.length}>
				<ArrowWrapper>
					<img src="/assets/img/png/monitoring/arrow.png" height={550}/>
				</ArrowWrapper>
				{/*<Progress
					percent={80}
					percentPosition={{
						align: 'end',
						type: 'inner',
					}}

					size={[600, 40]}
				/>*/}
				<Button type="primary" onClick={() => load()} size="large">Загрузить</Button>
			</LoadDataWrapper>
		</Wrapper>
	)
}

export default Map

const Wrapper = styled.div`
    display: flex;
    flex: 1;
    position: absolute;
    left: 0;
    right: 0;
    height: 550px;
`
const MapWrapper = styled.div`
    display: flex;
    flex: 1;
    filter: ${props => props.silver ? 'grayscale(100%)' : 'none'};
`
const LoadDataWrapper = styled.div`
    position: absolute;
    display: ${props => props.show ? 'flex' : 'none'};
    align-items: center;
    justify-content: center;
    flex: 1;
    width: 100%;
    height: 100%;
    transition: background-color 0.5s, opacity 0.5s ease;
    opacity: 0;

    :hover {
        background-color: rgba(0, 0, 0, 70%);
        opacity: 1;
    }
`
const ArrowWrapper = styled.div`
    position: absolute;
`
