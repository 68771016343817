import {useMemo} from "react"
import {Layer, Marker, Source} from "react-map-gl"
import styled from "styled-components"
import {PointStatuses} from "../../../../library/helper/point"

const TerminalPointsLayer = ({routes, activeRoute}) => {
	const data = useMemo(() => {
		let result = {
			type: "FeatureCollection",
			features: []
		}

		if (!routes || !routes.length) return result

		for (const route of routes) {
			for (const point of route.points) {
				result.features.push({
					type: "Feature",
					geometry: {
						type: "Point",
						coordinates: [point.location.lng, point.location.lat],
					},
					properties: {
						id: point.id,
						route_id: point.route_id,
						position: point.position,
						//color: PointStatuses[point.status].color,
						color: "#b2ada3",
						//status: point.status
					}
				})
			}
		}

		return result
	}, [routes])

	const POINTS_LAYER = useMemo(() => {
		const result = {
			id: "points",
			type: "circle",
			metadata: {type: "point"},
			paint: {
				"circle-radius": 10,
				"circle-color": ["get", "color"],
				"circle-stroke-width": 1,
				"circle-stroke-color": "#000000"
			},
			/*layout: {
				// сверху точки из активного МЛ или наведенного
				"circle-sort-key": ["case", ["==", ["get", "route_id"], activeRoute], ["+", 5, ["get", "position"]], ['in', ['get', 'status'], ['literal', ['canceled:manager', 'canceled:driver', 'canceled:api']]], 3, ['in', ['get', 'status'], ['literal', ['on_way', 'arrived']]], 2, ['==', ['get', 'status'], 'created'], 1, 0]
			},*/
			//filter: ["any", ["all"]]
		}

		// ставим точки активного МЛ наверх, скрываем остальные и уходим
		/*if (activeRoute) {
			result.filter[1].push(["==", ["get", "route_id"], activeRoute])
			return result
		}*/


		return result
	}, [activeRoute])

	const RADIUS_LAYER = useMemo(() => {
		const result = {
			id: "radius",
			type: "circle",
			metadata: {type: "radius"},
			minzoom: 14,
			paint: {
				"circle-radius": [
					'interpolate',
					['exponential', 2],
					['zoom'],
					0, 0,
					20, [
						'/',
						['/', 100, 0.075],
						['cos', ['*', ['get', 'lat'], ['/', Math.PI, 180]]]
					]
				],
				"circle-color": "rgba(24, 144, 255, .1)",
				"circle-stroke-width": 1,
				"circle-stroke-color": "rgba(24, 144, 255, .7)"
			},
			/*layout: {
				// сверху точки из активного МЛ или наведенного
				"circle-sort-key": ["case", ["==", ["get", "route_id"], activeRoute], ["+", 5, ["get", "position"]], ['in', ['get', 'status'], ['literal', ['canceled:manager', 'canceled:driver', 'canceled:api']]], 3, ['in', ['get', 'status'], ['literal', ['on_way', 'arrived']]], 2, ['==', ['get', 'status'], 'created'], 1, 0]
			},*/
			//filter: ["==", ["get", "route_id"], activeRoute]
		}

		return result
	}, [activeRoute])

	/*const activeRoutePoints = useMemo(() => {
		if (!activeRoute) return []
		const result = []
		for (const point of points) {
			if (!point.address_location || point.route_id !== activeRoute) continue
			result.push({
				id: point.id,
				location: point.address_location,
				position: point.position,
				color: PointStatuses[point.status].color
			})
		}

		return result.sort((a, b) => a.position - b.position)
	}, [activeRoute])*/


	return (
		<>
			<Source type="geojson" data={data}>
				<Layer {...POINTS_LAYER} />
				<Layer {...RADIUS_LAYER} beforeId="points"/>
			</Source>
			{/*{activeRoutePoints.map((point, index) => (
				<Marker key={"marker-" + index}
						longitude={point.location[1]}
						latitude={point.location[0]}>
					<ActivePoint color={point.color}>{point.position}</ActivePoint>
				</Marker>
			))}*/}
		</>
	)
}

export default TerminalPointsLayer

const ActivePoint = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    border-radius: 99px;
    border: solid 1px #000000;
    background: ${props => props.color};
    font-family: Arial;
    font-size: 13px;
    line-height: 13px;
    font-weight: bold;
    cursor: pointer;
`
