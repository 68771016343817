import {Map} from "react-map-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import {useEffect, useMemo, useRef, useState} from "react"
import VanRightIcon from "../../assets/img/icon/svg/van-right.svg"
import PlaceIcon from "../../assets/img/icon/svg/place.svg"
import VanLeftIcon from "../../assets/img/icon/svg/van-left.svg"
import TruckRightIcon from "../../assets/img/icon/svg/truck-right.svg"
import TruckLeftIcon from "../../assets/img/icon/svg/truck-left.svg"
import TrailerRightIcon from "../../assets/img/icon/svg/trailer-right.svg"
import TrailerLeftIcon from "../../assets/img/icon/svg/trailer-left.svg"
import TrackArrowIcon from "../../assets/img/icon/svg/track-arrow.svg"
import TrackParkingIcon from "../../assets/img/icon/svg/track-parking.svg"
import TrackParkingLongIcon from "../../assets/img/icon/svg/track-parking-long.svg"
import TrackSpeedIcon from "../../assets/img/icon/svg/track-speed.svg"
import TransportArrowOnlineIcon from "../../assets/img/icon/svg/transport-arrow-online.svg"
import TransportArrowParkingIcon from "../../assets/img/icon/svg/transport-arrow-parking.svg"
import TransportArrowDowntimeIcon from "../../assets/img/icon/svg/transport-arrow-downtime.svg"
import TransportArrowHistoryIcon from "../../assets/img/icon/svg/transport-arrow-history.svg"

const icons = [
	{name: "van-right-icon", size: 361, source: VanRightIcon},
	{name: "place-icon", size: 100, source: PlaceIcon},
	{name: "van-left-icon", size: 361, source: VanLeftIcon},
	{name: "truck-right-icon", size: 480, source: TruckRightIcon},
	{name: "truck-left-icon", size: 480, source: TruckLeftIcon},
	{name: "trailer-right-icon", size: 550, source: TrailerRightIcon},
	{name: "trailer-left-icon", size: 550, source: TrailerLeftIcon},
	{name: "track-arrow-icon", size: 64, source: TrackArrowIcon},
	{name: "track-parking-icon", size: 497, source: TrackParkingIcon},
	{name: "track-parking-long-icon", size: 497, source: TrackParkingLongIcon},
	{name: "track-speed-icon", size: 100, source: TrackSpeedIcon},
	{name: "transport-arrow-online", size: 100, source: TransportArrowOnlineIcon},
	{name: "transport-arrow-parking", size: 100, source: TransportArrowParkingIcon},
	{name: "transport-arrow-downtime", size: 100, source: TransportArrowDowntimeIcon},
	{name: "transport-arrow-history", size: 100, source: TransportArrowHistoryIcon},
]

const Mapbox = ({...props}) => {
	const mapRef = useRef()

	const [ready, setReady] = useState(false)

	useEffect(() => {
		setTimeout(() => {
			icons.map(icon => {
				const image = new Image(icon.size, icon.size)
				image.onload = () => mapRef.current.addImage(icon.name, image)
				image.src = icon.source
			})
		}, 50)
	}, [mapRef])

	const initialViewState = useMemo(() => {
		return props.initialViewState ?? {
			longitude: 37.5877594,
			latitude: 55.7421473,
			zoom: 10.2
		}
	}, [props])

	return (
		<Map
			ref={mapRef}
			initialViewState={initialViewState}
			mapboxAccessToken="pk.eyJ1IjoidHJpbjRpayIsImEiOiJjbDFub21xcHYwcnR3M2Nxcms4YW53Mzl4In0.ncuH_mrNOECKI2J1TCemPg"
			mapStyle="mapbox://styles/trin4ik/cla5p19g600ry15mg7apzgg6a"
			//mapStyle="mapbox://styles/mapbox/light-v11"
			touchZoomRotate={false}
			boxZoom={false}
			dragRotate={false}
			style={{position: 'absolute', top: 0, right: 0, bottom: 0, left: 0}}
			{...props}
		>
			{/*{ready.jpg ? props.children : null}*/}
		</Map>
	)
}

export default Mapbox
